<template>
  <div class="bodycont">
    <comHeader />
      <div class="aboutbanner"></div>
       <div class="indexMinker indexMinkerSd" style="padding: 25px 0;"></div>
         <div class="dic_inner" >
           <div class="newscont">
              <div class="newstitle">公司新闻</div>
              <div class="ul">
                  <a class="li dic_flex" href="/notice/1">
                    <div class="li-left">
                      <img src="../../assets/images/news/news61.png" alt="">
                    </div>
                     <div class="li-right">
                        <h3 class="stitle">递进式执法 实现现场执法简易化</h3>
                        <div class="sdes">我公司为钟祥市开发一套交警执法小程序，将交通警察、村干部、综治干部纳入到交通执法一体化治理体系中。通过人工智能，实现现场执法简易化，利用协同作战，提升执法效果，降低钟祥市交通违法情况</div>
                        <div class="look dic_flex">
                          <span class="date">2023-12-08</span>
                          <span class="xq">查看详情 <i class="icor iconfont">&#xe647;</i></span>
                        </div>
                     </div>
                  </a>
                  <a class="li dic_flex" href="/notice/2">
                    <div class="li-left">
                        <img src="../../assets/images/news/news2.png" alt="">
                    </div>
                     <div class="li-right">
                        <h3 class="stitle">全数据汇聚、全要素管控、全流程闭环 钟祥公安打造“治安要素智慧管控平台”</h3>
                        <div class="sdes">建立和完善矛盾纠纷多元化解机制，对于保障群众合法权益、促进社会公平正义具有重要意义。近年来，伴随着我国社会经济快速发展、经济体制变革不断深化、社会利益格局不断调整，社会矛盾纠纷处于频发时期，且呈现出纠纷主体多元化、利益诉求复杂化、纠纷类型多样化等特点。</div>
                        <div class="look dic_flex">
                          <span class="date">2024-01-13</span>
                          <span class="xq">查看详情 <i class="icor iconfont">&#xe647;</i></span>
                        </div>
                     </div>
                  </a>
                    <a class="li dic_flex" href="/notice/3">
                    <div class="li-left"> <img src="../../assets/images/news/news31.png" alt=""></div>
                     <div class="li-right">
                        <h3 class="stitle">新春开工！新征程，再出发！</h3>
                        <div class="sdes">2024年2月18日，全体员工一起开总结大会，主要内容：2023年总结，分析现状；2024年展望，规划未来发展及公司管理、财务、技术培训。</div>
                        <div class="look dic_flex">
                          <span class="date">2024-02-18</span>
                          <span class="xq">查看详情 <i class="icor iconfont">&#xe647;</i></span>
                        </div>
                     </div>
                  </a>
              </div>
           </div>
         </div>
    <indexFoot />
    <bNav />
  </div>

</template>

<script>
  import comHeader from '@/components/header.vue'
  import bNav from '@/components/bottomNav.vue'
   import indexFoot from '@/components/indexfoot.vue'
  import api from '@/config/api'
  import {
    getRequst,
    postRequest
  } from '@/api/index'
  export default {
    components: {
      comHeader,
      bNav,
      indexFoot
    },
    data() {
      return {
        nodata: require('../../assets/images/infonodata.png'),
      }
    },
    created() {

    },
    mounted() {

    },
    methods: {

    }
  }
</script>
<style  lang="less" scoped>
.aboutbanner{
    width:100%;
    height:300px;
    background: url(../../assets/images/about/about.png) no-repeat center center;
    background-size: cover;
  }
  .aboutNav{
    margin-top:20px;
    justify-content: space-around;
    align-items: center;
    height:44px;
  }
  .newstitle{
    font-size: 32px;
    text-align: center;
    margin:60px 0;
  }
  .li-left{
    width:460px;
    height:250px;
    background: #f2f2f2;
    margin-right: 30px;
    overflow:hidden;
  }
  .li-left img{
    width:100%;
    height:auto;
  }
  .li{
    height:250px;
    margin-bottom:45px;
  }
  .stitle{
    font-size: 18px;
    text-decoration: blink;
    width: 760px;
    height: 60px;
    font-size: 22px;
    font-weight: 600;
    color: #2F4056;
    line-height: 30px;
  }
  .sdes{
    font-size: 14px;
    line-height:28px;
  }
  .li-right{
    height:250px;
    position: relative;
    width:760px;
  }
  .look{
    position: absolute;
    bottom:10px;
    width:100%;
    justify-content: space-between;
  }
  .look span{
    display: block;
  }
  .look span.date{
    font-size: 14px;
  }
  .look span.xq{
    color:#0075ff;
    font-size: 14px;
  }
  .li{
    cursor: pointer;
  }
  </style>